import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  ${({ fullSize }) => fullSize && 'margin 0 -10px; overflow-y: auto'}
`

export const Title = styled.div`
  font-size: ${({ fullSize }) => (fullSize ? '1.35em' : '1em')};
  font-weight: bold;
  padding: 13px 10px 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  a {
    color: #c62326;
    text-decoration: none;
  }

  ${({ fullSize }) => fullSize && 'padding: 13px 20px 20px 20px;'}
`

export const NewsTitle = styled.div`
  margin-bottom: 5px;

  a {
    color: #424242;
    font-size: ${({ fullSize }) => (fullSize ? '0.95em' : '0.85em')};
    font-weight: normal;
    text-decoration: none;

    &:hover {
      color: #1b85d6;
    }
  }
`

export const NewsTime = styled.div`
  color: #acacac;
  font-size: 0.85em;
`

export const NewsRow = styled.div`
  ${({ fullSize }) =>
    fullSize &&
    `
      border-top: 1px solid #DEDEDE;
      padding-top: 24px;
      padding-right: 20px;
      padding-left: 20px;
  `}

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

export const NewsContainer = styled.div`
  padding: 10px 10px 13px 10px;
  overflow-y: ${({ fullSize }) => (fullSize ? 'hidden' : 'auto')};
  scrollbar-width: none;
  margin-top: ${({ marginTop }) => marginTop}px;

  ${({ fullSize }) =>
    fullSize &&
    `
      padding: 0;
  `}

  ${({ fullSize, marginTop }) =>
    !fullSize &&
    `
      &::after {
        content: '';
        position: absolute;
        background: linear-gradient(180deg, rgba(255,255,255,0.3) 0%, #FFFFFF 100%);
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0px;
        height: 15px;
        border-bottom-left-radius: 15px;
      }

      &::before {
        content: '';
        position: absolute;
        background: linear-gradient(180deg, rgba(255,255,255,0.8) 100%, rgba(255,255,255,0.3) 0%);
        z-index: 1;
        left: 0;
        right: 0;
        top: ${marginTop}px;
        height: 15px;
      }
  `}

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const MContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 3fr;
`

export const MExpand = styled.div`
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c62326;
  font-size: 0.85em;
  padding: 0 5px;
  cursor: pointer;
  height: 100%;
  white-space: nowrap;

  img {
    height: 15px;
    width: 15px;
    margin-right: 10px;
  }
`

export const MNewsContainer = styled.div`
  background-color: #fff;
  padding: 5px 0;
  min-height: 63px;
  box-sizing: border-box;
  display: flex;
`

export const MNewsTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  animation-timing-function: ease-in-out;
  animation-duration: ${({ fadeOutDuration }) => fadeOutDuration / 1000}s;

  a {
    color: #4f4f50;
    text-decoration: none;
    color: #4f4f50;
    font-size: 0.85em;
  }
`

export const Arrow = styled.div`
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    user-select: none;
    height: 14px;
    width: 14px;
    ${({ rotate }) => rotate && `transform: rotate(180deg);`}
  }
`
export const PaginationWrapperContainer = styled.div`
  height: 40px;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 10px;
`
