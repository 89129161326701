import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CategoryList from '../components/Category/categoryList'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const CategoryPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Layout title={siteTitle} location={location} lang={'ar'}>
      <SEO
        title={siteTitle}
        lang="ar"
        canonicalUrl={`${process.env.GATSBY_BASE_URL}category/`}
      />
      <CategoryList />
    </Layout>
  )
}

export default CategoryPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
