import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  Container,
  Title,
  NewsTitle,
  NewsRow,
  NewsContainer
} from '../LatestNews/styles'
import { GridLoader, Spinner } from '../grid/grid.styled'

const CategoryList = ({ getCategories, data, isFetching }) => {
  useEffect(() => {
    if (!data.length) {
      getCategories()
    }
  }, [data])

  const renderCategoryList = () => {
    if (isFetching && isEmpty(data)) {
      return (
        <GridLoader>
          <Spinner />
        </GridLoader>
      )
    }

    if (!isFetching && !isEmpty(data)) {
      return (
        <>
          <Title fullSize style={{ position: 'relative', color: '#c62326' }}>
            التصنيفات
          </Title>
          {data.map(({ ar, slug, id }) => (
            <NewsRow fullSize key={id}>
              <NewsTitle fullSize>
                <Link to={decodeURI(slug)}>{ar}</Link>
              </NewsTitle>
            </NewsRow>
          ))}
        </>
      )
    }

    return null
  }

  return (
    <Container fullSize>
      <NewsContainer fullSize>{renderCategoryList()}</NewsContainer>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: () => dispatch({ type: 'CATEGORY_REQUESTED' })
  }
}

const mapStateToProps = ({ categories }) => {
  const { data, isFetching } = categories

  return { data, isFetching }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)
